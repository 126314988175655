import { useEffect, useState } from "react"

export function useCsrfToken() {
  const [csrfToken, setCsrfToken] = useState()

  useEffect(() => {
    const abortController = new AbortController()
    const signal = abortController.signal

    fetch("/app/auth/api/csrf", { cache: "no-store", signal })
      .then(res => res.json())
      .then(res => {
        setCsrfToken(res.csrfToken)
      })

    return () => {
      abortController.abort("abort duplicated requests")
    }
  }, [])

  return {
    csrfToken,
    isLoading: csrfToken ? false : true,
  }
}
