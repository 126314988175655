import { useCsrfToken } from "../hooks"
import React, {
  type FC,
  type PropsWithChildren,
  createContext,
  useContext,
} from "react"

interface CsrfTokenContext {
  csrfToken: string | undefined
  isLoading: boolean
}

const Context = createContext<CsrfTokenContext>({} as CsrfTokenContext)
export const useCsrfTokenContext = () => {
  const context = useContext<CsrfTokenContext>(Context)
  if (context === undefined) {
    throw new Error("Context must be used within a CSRF Provider")
  }
  return context
}

export const CsrfTokenProvider: FC<PropsWithChildren> = ({ children }) => {
  const { csrfToken, isLoading } = useCsrfToken()

  return (
    <Context.Provider value={{ csrfToken, isLoading }}>
      {children}
    </Context.Provider>
  )
}
