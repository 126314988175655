// Generated by Avo VERSION 120.0.0. You should never have to make changes to this file.
// If you find yourself in the situation where you have to edit the file please contact us at hi@avo.app.
// If you encounter a git conflict in this file run `avo pull` and it will be resolved automatically.
/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export enum AvoEnv {
  Prod = "prod",
  Dev = "dev",
}

export interface CustomDestination {
  make?(env: AvoEnv, apiKey: string): void;
  logEvent?: (eventName: string, eventProperties: Record<string, any>) => void;
  setUserProperties?: (userId: string, userProperties: Record<string, any>) => void;
  identify?: (userId: string) => void;
  unidentify?: () => void;
  logPage?: (pageName: string, eventProperties: Record<string, any>) => void;
  revenue?: (amount: number, eventProperties: Record<string, any>) => void;
  setGroupProperties?: (
    groupType: string,
    groupId: string,
    groupProperties: Record<string, any>,
  ) => void;
  addCurrentUserToGroup?: (
    groupType: string,
    groupId: string,
    groupProperties: Record<string, any>,
  ) => void;
  logEventWithGroups?: (
    eventName: string,
    eventProperties: any,
    groupTypesToGroupIds: Record<string, string>,
  ) => void;
}

// @ts-ignore
interface AvoAssertMessage {
  eventName?: string;
  tag?: string;
  propertyId?: string;
  message?: string;
  additionalProperties?: string[],
  shape?: any,
  shapeUserProps?: any,
  actualType?: string
}

let __AVO_ENV__: AvoEnv | null = null;
// @ts-ignore
let __AVO_NOOP__: boolean = false;
// @ts-ignore
let __AVO_LOGGER__: AvoLogger | null = null;
// @ts-ignore
let __STRICT__: boolean | null = null;
// @ts-ignore
let __REPORT_FAILURE_AS__: 'error' | 'warn' | 'log' | null = null;

// @ts-ignore
let __WEB_DEBUGGER__: boolean = true;
export const avoInspectorApiKey = "DBBdqjek7ukumoIVVXE0";
// @ts-ignore
interface AvoInspector {}
let __INSPECTOR__: AvoInspector | null = null;


interface AvoLogger {
  logDebug(env: AvoEnv | null, message: string): boolean;
  logWarn(env: AvoEnv | null, message: string): boolean;
  logError(env: AvoEnv | null, error: string): boolean;
}

enum webDebuggerArea {
  BottomRight = "BottomRight",
  BottomLeft = "BottomLeft",
  TopRight = "TopRight",
  TopLeft = "TopLeft"
}

interface bottomRightParameters {
  bottom: number;
  right: number;
}

interface bottomLeftParameters {
  bottom: number;
  left: number;
}

interface topRightParameters {
  top: number;
  right: number;
}

interface topLeftParameters {
  top: number;
  left: number;
}

interface webDebuggerPosition {
  position: webDebuggerArea;
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}

interface webDebuggerOptions {
  position?: webDebuggerPosition;
}

interface WebDebuggerPositionSetter {
  BottomRight(p: bottomRightParameters): webDebuggerPosition;
  BottomLeft(p: bottomLeftParameters): webDebuggerPosition;
  TopRight(p: topRightParameters): webDebuggerPosition;
  TopLeft(p: topLeftParameters): webDebuggerPosition;
}

export const WebDebuggerPosition: WebDebuggerPositionSetter = {
  BottomRight: ({ bottom, right }) => ({
    position: webDebuggerArea.BottomRight,
    bottom,
    right,
  }),
  BottomLeft: ({ bottom, left }) => ({
    position: webDebuggerArea.BottomLeft,
    bottom,
    left,
  }),
  TopRight: ({ top, right }) => ({
    position: webDebuggerArea.TopRight,
    top,
    right,
  }),
  TopLeft: ({ top, left }) => ({
    position: webDebuggerArea.TopLeft,
    top,
    left,
  }),
}


let InternalAvoLogger: any = {
  logEventSent: function logEventSent(eventName: string, eventProperties: any, userProperties: any) {
    const message = "Event Sent:" + eventName + "Event Props:" + JSON.stringify(eventProperties) + "User Props:" + JSON.stringify(userProperties);

    if (__AVO_LOGGER__ && __AVO_LOGGER__.logDebug && __AVO_LOGGER__.logDebug(__AVO_ENV__, message)) {
      return
    }
    typeof console !== 'undefined' && console.log("[avo] Event Sent:", eventName, "Event Props:", eventProperties, "User Props:", userProperties);
  },

  log: function log(message: string) {
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logDebug && __AVO_LOGGER__.logDebug(__AVO_ENV__, message)) {
      return
    }
    typeof console !== 'undefined' && console.log("[avo] " + message);
  },

  warn: function warn(message: string) {
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logWarn && __AVO_LOGGER__.logWarn(__AVO_ENV__, message)) {
      return
    }
    typeof console !== 'undefined' && console.warn("[avo] " + message);
  },

  error: function error(message: string, error: string) {
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logError && __AVO_LOGGER__.logError(__AVO_ENV__, message + error)) {
      return
    }
    typeof console !== 'undefined' && console.error("[avo] " + message, error);
  }
};

function convertPropertiesArrayToMap(propertiesArray: [{id: string, name: string, value: string}]): {string: string | null | undefined} {
    let result: {string: string} = {}

    propertiesArray.forEach(value => {
      result[value.name] = value.value
    })

    return result
}

// @ts-ignore
let array_difference: any;
// @ts-ignore
let AvoAssert: any;
array_difference = function array_difference(a1: any[], a2: any[]) {
  let result: any[] = [];
  for (let i = 0; i < a1.length; i++) {
    if (a2.indexOf(a1[i]) === -1) {
      result.push(a1[i]);
    }
  }
  return result;
}

AvoAssert = {
  assertObject: function assertObject(propertyId: string, propName: string, obj: any) {
    if (typeof obj !== 'object') {
      let message = propName +
          ' should be of type object but you provided type ' +
          typeof obj +
          ' with value ' +
          JSON.stringify(obj);
      return [{tag: 'expectedObjectType', propertyId, message, actualType: typeof obj}];
    } else {
      return [];
    }
  },

  assertString: function assertString(propertyId: string, propName: string, str: string) {
    if (typeof str !== 'string') {
      let message = propName +
          ' should be of type string but you provided type ' +
          typeof str +
          ' with value ' +
          JSON.stringify(str);
      return [{tag: 'expectedStringType', propertyId, message, actualType: typeof str}];
    } else {
      return [];
    }
  },

  assertInt: function assertInt(propertyId: string, propName: string, int: number) {
    if (typeof int === 'number' && int !== Math.round(int)) {
      let message = propName +
          ' should be of type int but you provided type float with value ' +
          JSON.stringify(int);
      return [{tag: 'expectedIntType', propertyId, message, actualType: 'float'}];
    } else if (typeof int !== 'number') {
      let message = propName +
          ' should be of type int but you provided type ' +
          typeof int +
          ' with value ' +
          JSON.stringify(int);
      return [{tag: 'expectedIntType', propertyId, message, actualType: typeof int}];
    } else {
      return [];
    }
  },

  assertLong: function assertLong(propertyId: string, propName: string, long: number) {
    if (typeof long === 'number' && long !== Math.round(long)) {
      let message = propName +
          ' should be of type long but you provided type float with value ' +
          JSON.stringify(long);
      return [{tag: 'expectedLongType', propertyId, message, actualType: 'float'}];
    } else if (typeof long !== 'number') {
      let message = propName +
          ' should be of type long but you provided type ' +
          typeof long +
          ' with value ' +
          JSON.stringify(long);
      return [{tag: 'expectedLongType', propertyId, message, actualType: typeof long}];
    } else {
      return [];
    }
  },

  assertFloat: function assertFloat(propertyId: string, propName: string, float: number) {
    if (typeof float !== 'number') {
      let message = propName +
          ' should be of type float but you provided type ' +
          typeof float +
          ' with value ' +
          JSON.stringify(float);
      return [{tag: 'expectedFloatType', propertyId, message, actualType: typeof float}];
    } else {
      return [];
    }
  },

  assertBool: function assertBool(propertyId: string, propName: string, bool: boolean) {
    if (typeof bool !== 'boolean') {
      let message = propName +
          ' should be of type boolean but you provided type ' +
          typeof bool +
          ' with value ' +
          JSON.stringify(bool);
      return [{tag: 'expectedBoolType', propertyId, message, actualType: typeof bool}];
    } else {
      return [];
    }
  },

  assertMax: function assertMax(
    propertyId: string,
    propName: string,
    max: number,
    value: number
  ) {
    if (value > max) {
      let message = propName +
        ' has a maximum value of ' +
        max +
        ' but you provided the value ' +
        JSON.stringify(value);
      return [{tag: 'expectedMax', propertyId, message}];
    } else {
      return [];
    }
  },

  assertMin: function assertMin(
    propertyId: string,
    propName: string,
    min: number,
    value: number
  ) {
    if (value < min) {
      let message = propName +
        ' has a minimum value of ' +
        min +
        ' but you provided the value ' +
        JSON.stringify(value);
      return [{tag: 'expectedMin', propertyId, message}];
    } else {
      return [];
    }
  },

  assertList: function assertList(propertyId: string, propName: string, value: any) {
    if (!Array.isArray(value)) {
      let message = propName + ' should be of type list but you provided type ' + typeof value;
      return [{tag: 'expectedList', propertyId, message}];
    } else {
      return [];
    }
  },

  assertNoAdditionalProperties: function assertNoAdditionalProperties(eventName: string, input: string[], spec: string[]) {
    let additionalKeys = array_difference(input, spec);
    if (additionalKeys.length) {
      let message = "Additional properties when sending event " + eventName + ": " + JSON.stringify(additionalKeys);
      return [{tag: 'expectedNoAdditionalProperties', additionalProperties: additionalKeys, message: message}];
    } else {
      return [];
    }
  },

  assertNoAdditionalUserProperties: function assertNoAdditionalProperties(eventName: string, input: string[], spec: string[]) {
    let additionalKeys = array_difference(input, spec);
    if (additionalKeys.length) {
      let message = "Additional user properties when sending event " + eventName + ": " + JSON.stringify(additionalKeys);
      return [{tag: 'expectedNoAdditionalUserProperties', additionalProperties: additionalKeys, message: message}];
    } else {
      return [];
    }
  },
};

let _avo_invoke: any;
let _avo_invoke_meta: any;
let _avo_sampling_rate = 1.0;
_avo_invoke = function _avo_invoke(env: AvoEnv, eventId: string, hash: string, messages: {tag: string, propertyId: string}[], origin: string) {
  // @ts-ignore
  if (typeof (window as any) === 'undefined') { return; }
  if (_avo_sampling_rate > 0) {
    if (Math.random() < _avo_sampling_rate) {
      // @ts-ignore
      fetch("https://api.avo.app/i", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          "ac": "sXcWxxCUDS0F60bJuf9I",
          "br": "master",
          "en": env,
          "ev": eventId,
          "ha": hash,
          "sc": "SyotC3NRBiaKYbijsQS9",
          "se": (new Date()).toISOString(),
          "so": "7A2nctxgx",
          "va": messages.length === 0,
          "me": messages,
          "or": origin
        })
      }).then(function(res: any) { return res.json(); }).then(function(data: any) { _avo_sampling_rate = data.sa; }).catch(function() {});
    }
  }
}

_avo_invoke_meta = function _avo_invoke_meta(env: AvoEnv, type: string, messages: {tag: string, propertyId: string}[], origin: string) {
  // @ts-ignore
  if (typeof (window as any) === 'undefined') { return; }
  if (_avo_sampling_rate > 0) {
    if (Math.random() < _avo_sampling_rate) {
      // @ts-ignore
      fetch("https://api.avo.app/i", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          "ac": "sXcWxxCUDS0F60bJuf9I",
          "br": "master",
          "en": env,
          "ty": type,
          "sc": "SyotC3NRBiaKYbijsQS9",
          "se": (new Date()).toISOString(),
          "so": "7A2nctxgx",
          "va": messages.length === 0,
          "me": messages,
          "or": origin
        })
      }).then(function(res: any) { return res.json(); }).then(function(data: any) { _avo_sampling_rate = data.sa; }).catch(function() {});
    }
  }
}


let _avo_debugger_log: any;
let _avo_debugger_events_during_boot: any = [];
let _avo_debugger_ready = false;

if (typeof (window as any) !== 'undefined') {
  window.addEventListener("message", function(event) {
    if (event.origin !== "https://www.avo.app") {
      return;
    }
    let iframe: any = document.getElementById("avo-debugger");
    if (iframe && event && event.data && event.data.type_ === "avo-debugger-update-style") {
      iframe.style = event.data.style;
    } else if (iframe && event && event.data && event.data.type_ === "avo-debugger-ready") {
      let message = {
        type_: "avo-debugger-boot-events",
        schemaId: "SyotC3NRBiaKYbijsQS9",
        href: window.location.href,
        events: _avo_debugger_events_during_boot
      };
      _avo_debugger_events_during_boot = [];
      _avo_debugger_ready = true;
      iframe.contentWindow.postMessage(message, "https://www.avo.app/_debugger")
    }
  });
}

_avo_debugger_log = function _avo_debugger_log(eventId: string, eventName: string, messages: any[], eventProperties: any[], userProperties: any[], groupProperties: any[]) {
  if (typeof (window as any) === 'undefined') { return; }
  let event = {
    eventId: eventId,
    eventName: eventName,
    messages: messages,
    timestamp: Date.now(),
    eventProperties,
    userProperties,
    groupProperties
  };

  if (_avo_debugger_ready) {
    let message = {type_: "avo-debugger-events", events: [event]};
    (document.getElementById("avo-debugger") as any).contentWindow.postMessage(message, "https://www.avo.app/_debugger")
  } else {
    _avo_debugger_events_during_boot.push(event);
  }
}

function _avo_debugger_send_position(position: webDebuggerPosition) {
  if (typeof window === 'undefined') { return; }
  window.addEventListener("message", function(event) {
    if (event.origin !== "https://www.avo.app") {
      return;
    }
    if (event.data.type_ === "avo-debugger-ready" && position !== null) {
      var message = {type_: "avo-debugger-position", position: position};
      document.getElementById("avo-debugger").contentWindow.postMessage(message, "https://www.avo.app/_debugger")
    }
  })
}


export const AuthenticationSystem = {
  'MURMUR': 'Murmur',
  'FUSION_AUTH': 'FusionAuth',
} as const;
export type AuthenticationSystemType = typeof AuthenticationSystem;
export type AuthenticationSystemValueType = AuthenticationSystemType[keyof AuthenticationSystemType];

export const AuthenticationMethod = {
  'PASSWORD': 'Password',
  'GOOGLE_OAUTH2': 'Google OAuth2',
  'SAML': 'SAML',
} as const;
export type AuthenticationMethodType = typeof AuthenticationMethod;
export type AuthenticationMethodValueType = AuthenticationMethodType[keyof AuthenticationMethodType];

let AmplitudeCustom: any;

export function initAvo(options: {env: AvoEnv; webDebugger?: boolean;
  webDebuggerOptions?: webDebuggerOptions; strict?: boolean; noop?: boolean;
  reportFailureAs?: 'error' | 'warn' | 'log'; inspector?: AvoInspector;
  avoLogger?: AvoLogger}, destinationOptions: any,
  AmplitudeCustomDestination: CustomDestination) {
  if (__AVO_ENV__ !== null) {
    return;
  }
  __AVO_ENV__ = options.env;
  if (options.avoLogger !== undefined) {
    __AVO_LOGGER__ = options.avoLogger;
  }
  if (options.noop === true) {
    __AVO_NOOP__ = true;
  }
  if (__AVO_NOOP__ && __AVO_ENV__ == AvoEnv.Prod) {
    InternalAvoLogger.warn("[avo] ****************************************************");
    InternalAvoLogger.warn("[avo] WARNING Avo cannot be initialized in noop mode in production:");
    InternalAvoLogger.warn("[avo] - Overwriting configuration with noop=false.");
    InternalAvoLogger.warn("[avo] - Please reach out if you want to be able to run Avo in production mode with noop=true");
    InternalAvoLogger.warn("[avo] ****************************************************");
    __AVO_NOOP__ = false;
  }
  if (__AVO_NOOP__) {
    InternalAvoLogger.log("[avo] ****************************************************");
    InternalAvoLogger.log("[avo] Avo is now initialized in noop mode. This means:");
    InternalAvoLogger.log("[avo] - No events will be sent");
    InternalAvoLogger.log("[avo] - No network requests are made");
    InternalAvoLogger.log("[avo] ****************************************************");
  }
  if (options.strict !== undefined) {
    __STRICT__ = options.strict !== false;
  }
  if (options.reportFailureAs !== undefined) {
    __REPORT_FAILURE_AS__ = options.reportFailureAs;
  }
  __WEB_DEBUGGER__ = !__AVO_NOOP__ && ((typeof window !== 'undefined' && (window as any).location.search.indexOf("avo_debug=1") > -1) || (options.webDebugger !== false && __AVO_ENV__ !== AvoEnv.Prod));
  if (!__AVO_NOOP__ && options.inspector !== undefined) {
    __INSPECTOR__ = options.inspector;
  } else if (__AVO_ENV__ !== 'prod') {
    InternalAvoLogger.warn("[avo] Avo Inspector not provided in initAvo() call");
  }

  destinationOptions = destinationOptions || {};

  if (__WEB_DEBUGGER__ && !__AVO_NOOP__) {
    if (options.webDebuggerOptions?.position) {
      _avo_debugger_send_position(options.webDebuggerOptions.position)
    }

(function() {
  if (typeof (window as any) === 'undefined') { return; }
  let init = function() {
    let iframe: any = document.createElement("iframe");
    document.body.appendChild(iframe);
    iframe.id = "avo-debugger";
    iframe.src = "https://www.avo.app/_debugger";
    iframe.style = "display: none;";
  };

  if (document.body) {
    init();
  } else {
    document.addEventListener('DOMContentLoaded', init);
  }
})();

  }
  if (!__AVO_NOOP__) {
    if (__AVO_ENV__ === AvoEnv.Prod) {
    }
    if (__AVO_ENV__ === AvoEnv.Dev) {
    }

    AmplitudeCustom = AmplitudeCustomDestination;
    if (__AVO_ENV__ === 'prod') {
      AmplitudeCustom && AmplitudeCustom.make && AmplitudeCustom.make(__AVO_ENV__, "6cfe80b109d58db414efa2dbae828525");
    } else if (__AVO_ENV__ === 'dev') {
      AmplitudeCustom && AmplitudeCustom.make && AmplitudeCustom.make(__AVO_ENV__, "ebe34b05f57a2a074057e1c18183de28");
    } else {
      console[__REPORT_FAILURE_AS__ || 'error']("[avo] No staging key is set for Amplitude (custom). Head to destination settings in Avo to set a staging key.");
      AmplitudeCustom && AmplitudeCustom.make && AmplitudeCustom.make(__AVO_ENV__, null);
    }
    if (__AVO_ENV__ === AvoEnv.Dev) {
      // debug console in Avo
      _avo_invoke_meta(__AVO_ENV__, 'init', [], 'init');
    }
  }
}

export function setAvoLogger(avoLogger: AvoLogger | null) {
  __AVO_LOGGER__ = avoLogger;
}

export interface UserSignedInProperties {
  authenticationMethod: AuthenticationMethodValueType;
  isDeepLink: boolean;
  newPasswordSet: boolean;
  failedAttempts: number;
  authenticationSystem: AuthenticationSystemValueType;
}
/**
 * User Signed In: A user has successfully completed the authentication process and is now logged in.
 *
 * @param properties the properties associatied with this event
 * @param properties.authenticationMethod: Describes the authentication method used to sign in in the platform. e.g. SSO, Google, Password
 * @param properties.isDeepLink: Has the user signed in using a deep link? Deep links are a type of link that send users directly to a specific part of the platform. e.g. directly to your own self refection to complete
 * @param properties.newPasswordSet: Indicates if the user has set a new password during the sign-in process
 * @param properties.failedAttempts: Number of failed attempts before a successful sign in
 * @param properties.authenticationSystem: The authentication system used to authenticate the user. The null values for this field prior to it being added indicate that the authentication system was Murmur
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/q9X9dheQmx}
 */
export function userSignedIn(properties: UserSignedInProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "bw7UM1M7tk", name: "Authentication method", value: properties.authenticationMethod});
  eventPropertiesArray.push({id: "sUQC2bSxem", name: "Is deep link", value: properties.isDeepLink});
  eventPropertiesArray.push({id: "h_aEw_LDJi", name: "New password set", value: properties.newPasswordSet});
  eventPropertiesArray.push({id: "kc9LEFeYqB", name: "Failed attempts", value: properties.failedAttempts});
  eventPropertiesArray.push({id: "DPeON5oVpy", name: "Authentication system", value: properties.authenticationSystem});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "q9X9dheQmx", "cb95c1ced6ab0d2dca4af7d06422bd1c081915d433d228390b5647a9ff3b8ccf", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("User Signed In", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("q9X9dheQmx", "User Signed In", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("User Signed In", eventProperties, "q9X9dheQmx", "cb95c1ced6ab0d2dca4af7d06422bd1c081915d433d228390b5647a9ff3b8ccf");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("User Signed In", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SignMinusinPageViewedProperties {
  errorPresent: string | null | undefined;
  pageSubdomain: string;
  authenticationSystem: AuthenticationSystemValueType;
}
/**
 * Sign-in Page Viewed: A non-logged in user views the sign-in page.
 *
 * @param properties the properties associatied with this event
 * @param properties.errorPresent: Indicates what error has occurred during the sign-in process, if any. e.g. 'Invalid email or password'
 * @param properties.pageSubdomain: Full page subdomain including geography (eu, us...)
 * @param properties.authenticationSystem: The authentication system used to authenticate the user. The null values for this field prior to it being added indicate that the authentication system was Murmur
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/FET9hwKT2u}
 */
export function signInPageViewed(properties: SignMinusinPageViewedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  properties.errorPresent !== undefined && properties.errorPresent !== null ?
    eventPropertiesArray.push({id: "6Fz-5JyLb6", name: "Error present", value: properties.errorPresent}) :
    eventPropertiesArray.push({id: "6Fz-5JyLb6", name: "Error present", value: null});
  eventPropertiesArray.push({id: "70FKJzvePt", name: "Page subdomain", value: properties.pageSubdomain});
  eventPropertiesArray.push({id: "DPeON5oVpy", name: "Authentication system", value: properties.authenticationSystem});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "FET9hwKT2u", "6229830cee27e004c9bf24a46af09a80897d453d9360fa1d11dd309d310d4746", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Sign-in Page Viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("FET9hwKT2u", "Sign-in Page Viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Sign-in Page Viewed", eventProperties, "FET9hwKT2u", "6229830cee27e004c9bf24a46af09a80897d453d9360fa1d11dd309d310d4746");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Sign-in Page Viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * SSO Subdomain Input Page Viewed: A non-logged in user views the SSO Subdomain Input landing page.
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/W2wgjTEpTu}
 */
export function ssoSubdomainInputPageViewed() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "W2wgjTEpTu", "ac6340024ad87d90a4ed7f2a650c10cc2b45e6b605ceba6caa5b3e7f55de75db", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("SSO Subdomain Input Page Viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("W2wgjTEpTu", "SSO Subdomain Input Page Viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("SSO Subdomain Input Page Viewed", eventProperties, "W2wgjTEpTu", "ac6340024ad87d90a4ed7f2a650c10cc2b45e6b605ceba6caa5b3e7f55de75db");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("SSO Subdomain Input Page Viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ForgotPasswordPageViewedProperties {
  authenticationSystem: AuthenticationSystemValueType;
}
/**
 * Forgot Password Page Viewed: A non-logged in user views the Forgot Password page.
 *
 * @param properties the properties associatied with this event
 * @param properties.authenticationSystem: The authentication system used to authenticate the user. The null values for this field prior to it being added indicate that the authentication system was Murmur
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/cgQLRGoKAc}
 */
export function forgotPasswordPageViewed(
  properties: ForgotPasswordPageViewedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "DPeON5oVpy", name: "Authentication system", value: properties.authenticationSystem});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "cgQLRGoKAc", "c938a5e644e0bf3f0b0b7c86a7b3b794f9b9e0b4692afb0da5facd7fff832279", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Forgot Password Page Viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("cgQLRGoKAc", "Forgot Password Page Viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Forgot Password Page Viewed", eventProperties, "cgQLRGoKAc", "c938a5e644e0bf3f0b0b7c86a7b3b794f9b9e0b4692afb0da5facd7fff832279");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Forgot Password Page Viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SetNewPasswordPageViewedProperties {
  errorPresent: string | null | undefined;
  authenticationSystem: AuthenticationSystemValueType;
}
/**
 * Set New Password Page Viewed: A non-logged in user views the page for creating a new password after clicking on the 'reset password' link on the email received.
 *
 * @param properties the properties associatied with this event
 * @param properties.errorPresent: Indicates what error has occurred during the sign-in process, if any. e.g. 'Invalid email or password'
 * @param properties.authenticationSystem: The authentication system used to authenticate the user. The null values for this field prior to it being added indicate that the authentication system was Murmur
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/2npnLqoCkY}
 */
export function setNewPasswordPageViewed(
  properties: SetNewPasswordPageViewedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  properties.errorPresent !== undefined && properties.errorPresent !== null ?
    eventPropertiesArray.push({id: "6Fz-5JyLb6", name: "Error present", value: properties.errorPresent}) :
    eventPropertiesArray.push({id: "6Fz-5JyLb6", name: "Error present", value: null});
  eventPropertiesArray.push({id: "DPeON5oVpy", name: "Authentication system", value: properties.authenticationSystem});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "2npnLqoCkY", "db0d1f8c98b8fcdc581dd6e45d3480c58f8117bdf46644ac41fad8b2256b74b7", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Set New Password Page Viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("2npnLqoCkY", "Set New Password Page Viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Set New Password Page Viewed", eventProperties, "2npnLqoCkY", "db0d1f8c98b8fcdc581dd6e45d3480c58f8117bdf46644ac41fad8b2256b74b7");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Set New Password Page Viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * Terms and Conditions Page Viewed: A user logging in for the first time views the Terms & Conditions page.
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/T0psvw3RTz}
 */
export function termsAndConditionsPageViewed() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "T0psvw3RTz", "6fc4b76088f8e7503f3dce5a95f687d5db8c588712bd54ab5924a96f4e0c6877", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Terms and Conditions Page Viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("T0psvw3RTz", "Terms and Conditions Page Viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Terms and Conditions Page Viewed", eventProperties, "T0psvw3RTz", "6fc4b76088f8e7503f3dce5a95f687d5db8c588712bd54ab5924a96f4e0c6877");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Terms and Conditions Page Viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface GoogleSignMinusinInitiatedProperties {
  authenticationSystem: AuthenticationSystemValueType;
}
/**
 * Google Sign-in Initiated: A non-logged in user starts the authentication process with Google.
 *
 * @param properties the properties associatied with this event
 * @param properties.authenticationSystem: The authentication system used to authenticate the user. The null values for this field prior to it being added indicate that the authentication system was Murmur
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/1h2FB4XInS}
 */
export function googleSignInInitiated(
  properties: GoogleSignMinusinInitiatedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "DPeON5oVpy", name: "Authentication system", value: properties.authenticationSystem});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "1h2FB4XInS", "e4a63a3496b056ef0a763afc4934ebc931677916552826c1543473a1d3197baf", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Google Sign-in Initiated", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("1h2FB4XInS", "Google Sign-in Initiated", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Google Sign-in Initiated", eventProperties, "1h2FB4XInS", "e4a63a3496b056ef0a763afc4934ebc931677916552826c1543473a1d3197baf");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Google Sign-in Initiated", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * SSO Sign-in Initiated: A non-logged in user starts the authentication process using SSO.
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/M7IEzNaemb}
 */
export function ssoSignInInitiated() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "M7IEzNaemb", "cd898818497dc409958c16ddc48b52a9969a4089e550a7b712079f61e7266d03", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("SSO Sign-in Initiated", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("M7IEzNaemb", "SSO Sign-in Initiated", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("SSO Sign-in Initiated", eventProperties, "M7IEzNaemb", "cd898818497dc409958c16ddc48b52a9969a4089e550a7b712079f61e7266d03");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("SSO Sign-in Initiated", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface PasswordResetInstructionsPageViewedProperties {
  authenticationSystem: AuthenticationSystemValueType;
}
/**
 * Password Reset Instructions Page Viewed: A non-logged in user views the page that comes after triggering a password recovery email with the instructions on how to reset the password.
 *
 * When to trigger this event:
 * 1. A user views the password reset instructions page.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/Oj752Od_WO/trigger/u_kpjvCEv
 *
 * @param properties the properties associatied with this event
 * @param properties.authenticationSystem: The authentication system used to authenticate the user. The null values for this field prior to it being added indicate that the authentication system was Murmur
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/Oj752Od_WO}
 */
export function passwordResetInstructionsPageViewed(
  properties: PasswordResetInstructionsPageViewedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "DPeON5oVpy", name: "Authentication system", value: properties.authenticationSystem});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "Oj752Od_WO", "797c2080b2add98dc0d32e54f6bfa581fd481e841644708362205254eeceb463", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Password Reset Instructions Page Viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("Oj752Od_WO", "Password Reset Instructions Page Viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Password Reset Instructions Page Viewed", eventProperties, "Oj752Od_WO", "797c2080b2add98dc0d32e54f6bfa581fd481e841644708362205254eeceb463");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Password Reset Instructions Page Viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ExpiredPasswordTokenPageViewedProperties {
  authenticationSystem: AuthenticationSystemValueType;
}
/**
 * Expired Password Token Page Viewed: A non-logged in user requests a new password reset after the previous email expired.
 *
 * When to trigger this event:
 * 1. A user views the expired password token page.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/Ha1zx6lLYQ/trigger/s0whQ6dtC
 *
 * @param properties the properties associatied with this event
 * @param properties.authenticationSystem: The authentication system used to authenticate the user. The null values for this field prior to it being added indicate that the authentication system was Murmur
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/Ha1zx6lLYQ}
 */
export function expiredPasswordTokenPageViewed(
  properties: ExpiredPasswordTokenPageViewedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "DPeON5oVpy", name: "Authentication system", value: properties.authenticationSystem});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "Ha1zx6lLYQ", "8078cd87cab70636fbfaab31d0b7ec4ea1e80a3c09f37775952cf325970b4c81", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Expired Password Token Page Viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("Ha1zx6lLYQ", "Expired Password Token Page Viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Expired Password Token Page Viewed", eventProperties, "Ha1zx6lLYQ", "8078cd87cab70636fbfaab31d0b7ec4ea1e80a3c09f37775952cf325970b4c81");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Expired Password Token Page Viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export default {
  AvoEnv,
  initAvo,
  avoInspectorApiKey,
  AuthenticationSystem,
  AuthenticationMethod,
  userSignedIn,
  signInPageViewed,
  ssoSubdomainInputPageViewed,
  forgotPasswordPageViewed,
  setNewPasswordPageViewed,
  termsAndConditionsPageViewed,
  googleSignInInitiated,
  ssoSignInInitiated,
  passwordResetInstructionsPageViewed,
  expiredPasswordTokenPageViewed,
}

// AVOMODULEMAP:"Avo"
// AVOEVENTMAP:["userSignedIn","signInPageViewed","ssoSubdomainInputPageViewed","forgotPasswordPageViewed","setNewPasswordPageViewed","termsAndConditionsPageViewed","googleSignInInitiated","ssoSignInInitiated","passwordResetInstructionsPageViewed","expiredPasswordTokenPageViewed"]
